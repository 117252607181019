/** @jsxImportSource @emotion/react */
import {
  FabricDefaultButton,
  FabricPrimaryButton,
  FilterBox,
  MessageBar,
  Pagination,
  TableList,
  Text,
  useIsMountedRef,
  useTheme,
} from "@adux/common-react";
import { debounce } from "@adux/common-react/lib/utils/shared";
import { css } from "@emotion/react";
import {
  MessageBarType,
  Modal,
  SelectionMode,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useGetContainer } from "@msbabylon/shell-core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import AppHyperLink from "src/components/AppHyperLink";
import { productDocumentations } from "src/constants/documentationLinks";
import BasePlanConfirmDialog from "src/containers/Reconcile/BasePlanConfirmDialog";
import RegionConfirmDialog from "src/containers/Reconcile/RegionConfirmDialog";
import { useDependencies } from "src/hook";
import messageIds from "src/locales/messageIds";
import {
  AccountItem,
  uiReconcileSelectAccountModel,
} from "src/store/shell-models/ui/reconcileSelectAccount";
import { cssTextTruncate } from "src/styles/util";
import { redirectToLandingPage } from "src/util/redirect";
import { getAzureRegionDisplayName } from "src/util/region";
import { equalsCaseInsensitive } from "src/util/string";

export default React.memo(() => {
  const intl = useIntl();
  const getContainer = useGetContainer();
  const theme = useTheme();
  const dependencies = useDependencies();

  const ui = getContainer(uiReconcileSelectAccountModel);

  useEffect(() => {
    dependencies.logger.logEvent(
      "info",
      "Reconcile",
      "entered reconcile account selection page."
    );
  }, [dependencies.logger]);

  useEffect(() => {
    ui.actions.onInitialize.dispatch({});
  }, [ui.actions.onInitialize]);

  const initialized = useSelector(() => ui.state.initialized);
  const loadingAccounts = useSelector(() => ui.state.loadingAccounts);
  const upgradingAccount = useSelector(() => ui.state.upgrading);
  const selectedAccount = useSelector(() => ui.getters.selectedAccount);
  const basePlanIsUsed = useSelector(() => ui.getters.basePlanIsUsed);

  const onSelectAccount = useCallback(
    (keys: string[]) => {
      ui.actions.setSelectedAccount.dispatch(keys[0]);
    },
    [ui.actions.setSelectedAccount]
  );
  const selectedAccountRegionMatched = useSelector(
    () => ui.getters.selectedAccountRegionMatched
  );

  const permittedMap = useSelector(() => ui.state.permittedMap);
  const allAccounts = useSelector(() => ui.getters.combinedAccounts);

  const [showRegionConfirmDialog, setShowRegionConfirmDialog] = useState(false);
  const [showBasePlanConfirmDialog, setShowBasePlanConfirmDialog] =
    useState(false);

  useEffect(() => {
    if (allAccounts.length === 1) {
      const account = allAccounts[0];
      if (
        permittedMap[account.accountName] === true &&
        equalsCaseInsensitive(account.publicNetworkAccess, "Enabled")
      ) {
        ui.actions.setSelectedAccount.dispatch(account.accountName);
        if (account.accountName) {
          if (basePlanIsUsed) {
            setShowBasePlanConfirmDialog(true);
          } else if (!selectedAccountRegionMatched) {
            setShowRegionConfirmDialog(true);
          }
        }
      }
    }
  }, [
    allAccounts,
    permittedMap,
    ui.actions.setSelectedAccount,
    basePlanIsUsed,
    selectedAccountRegionMatched,
  ]);

  const onConfirmRegionDialog = useCallback(() => {
    ui.actions.upgradeAsync.dispatch({});
  }, [ui.actions.upgradeAsync]);

  const onConfirmBasePlanDialog = useCallback(() => {
    if (selectedAccountRegionMatched) {
      onConfirmRegionDialog();
    } else {
      setShowBasePlanConfirmDialog(false);
      setShowRegionConfirmDialog(true);
    }
  }, [selectedAccountRegionMatched, onConfirmRegionDialog]);

  const onUpgrade = useCallback(() => {
    if (basePlanIsUsed) {
      setShowBasePlanConfirmDialog(true);
    } else {
      onConfirmBasePlanDialog();
    }
  }, [basePlanIsUsed, onConfirmBasePlanDialog]);

  const onCancel = useCallback(() => {
    redirectToLandingPage(
      allAccounts.length === 1 ? `/resource/${allAccounts[0].accountName}` : "",
      dependencies
    );
  }, [allAccounts, dependencies]);

  const onCancelRegionDialog = useCallback(() => {
    setShowRegionConfirmDialog(false);
  }, []);

  const onCancelBasePlanDialog = useCallback(() => {
    setShowBasePlanConfirmDialog(false);
  }, []);
  const currentPage = useSelector(() => ui.state.currentPage);
  const pageCount = useSelector(() => ui.getters.pageCount);
  const pageSize = useSelector(() => ui.state.pageSize);
  const setCurrentPage = useCallback(
    (page: number) => {
      ui.actions.setCurrentPage.dispatch(page - 1);
    },
    [ui.actions.setCurrentPage]
  );

  const filterText = useSelector(() => ui.state.filterText);
  const [localFilterText, setFilterText] = React.useState(filterText);
  const isMountedRef = useIsMountedRef();
  const setFilterTextDelayedDebounced = useMemo(
    () =>
      debounce((v: string) => {
        if (isMountedRef.current) {
          ui.actions.setFilterText.dispatch(v);
          ui.actions.setCurrentPage.dispatch(0);
        }
      }, 500),
    [isMountedRef, ui.actions.setCurrentPage, ui.actions.setFilterText]
  );

  useEffect(() => {
    setFilterTextDelayedDebounced(localFilterText);
  }, [setFilterTextDelayedDebounced, localFilterText]);

  const onFilterValueChange = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => {
      const updateValue = newValue || event?.currentTarget.value || "";
      setFilterText(updateValue);
    },
    [setFilterText]
  );

  const filteredAccounts = useSelector(() => ui.getters.filteredAccounts);
  const items = useSelector(() => ui.getters.currentPageAccountsTableItems);

  const disabledKeys = useMemo(
    // eslint-disable-next-line security/detect-object-injection
    () =>
      items
        .filter((e) => {
          return (
            permittedMap[e.key] !== true ||
            equalsCaseInsensitive(e.publicNetworkAccess, "Disabled")
          );
        })
        .map((e) => e.key),
    [items, permittedMap]
  );
  const selectedKeys = useMemo(
    () => (selectedAccount ? [selectedAccount.accountName] : []),
    [selectedAccount]
  );

  const columns = useMemo(() => {
    return [
      {
        key: "displayText",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.accountName,
        }),
        maxWidth: 300,
        onRender: (
          item: AccountItem & {
            permitted?: boolean | "INPROGRESS";
            displayText: string;
          }
        ) => {
          let title = item.displayText;
          let disabled = false;
          if (item.permitted === "INPROGRESS") {
            title = intl.formatMessage({
              id: messageIds.reconcile.upgrade.checkingPermissions,
            });
            disabled = true;
          } else if (item.permitted !== true) {
            title = intl.formatMessage({
              id: messageIds.reconcile.upgrade.noPermissionForThisAccount,
            });
            disabled = true;
          } else if (
            equalsCaseInsensitive(item.publicNetworkAccess, "Disabled")
          ) {
            title = intl.formatMessage({
              id: messageIds.reconcile.upgrade.noPublicNetworkAccess,
            });
            disabled = true;
          }
          return (
            <span
              style={{
                color: disabled
                  ? theme.semanticColors.disabledBodyText
                  : theme.semanticColors.bodyText,
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
              }}
              title={title}
            >
              {item.permitted === "INPROGRESS" && (
                <Spinner
                  size={SpinnerSize.xSmall}
                  styles={{ root: { marginRight: 8 } }}
                />
              )}
              <span css={cssTextTruncate}>{item.displayText}</span>
            </span>
          );
        },
      },
      {
        key: "subscription",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.subscription,
        }),
        maxWidth: 200,
      },
      {
        key: "location",
        name: intl.formatMessage({
          id: messageIds.reconcile.upgrade.location,
        }),
        minWidth: 60,
        maxWidth: 80,
        onRender: (
          item: AccountItem & {
            permitted?: boolean | "INPROGRESS";
            displayText: string;
          }
        ) => {
          return getAzureRegionDisplayName(intl, item.location);
        },
      },
    ];
  }, [
    intl,
    theme.semanticColors.bodyText,
    theme.semanticColors.disabledBodyText,
  ]);

  if (!initialized || loadingAccounts) {
    return (
      <Spinner
        css={css`
          display: flex;
          flex-grow: 1;
        `}
        size={SpinnerSize.large}
      />
    );
  }

  if (
    allAccounts.length === 1 &&
    selectedAccount &&
    (basePlanIsUsed || !selectedAccountRegionMatched)
  ) {
    return (
      <>
        <BasePlanConfirmDialog
          isOpen={showBasePlanConfirmDialog}
          onConfirm={onConfirmBasePlanDialog}
          onCancel={onCancel}
        />
        <RegionConfirmDialog
          isOpen={showRegionConfirmDialog}
          account={selectedAccount}
          onConfirm={onConfirmRegionDialog}
          onCancel={onCancel}
        />
      </>
    );
  }

  return (
    <>
      <Modal
        isOpen={!showRegionConfirmDialog && !showBasePlanConfirmDialog}
        isModeless={true}
        styles={{
          main: {
            padding: 32,
          },
        }}
      >
        <div
          css={css`
            width: 770px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow-y: hidden;
          `}
        >
          <div
            css={css`
              display: flex;
              flex: "1 1 auto";
              flex-direction: column;
              gap: 16px;
              overflow-y: hidden;
            `}
          >
            <div
              css={css`
                font-size: 36px;
                line-height: 32px;
                font-weight: 600;
              `}
            >
              {intl.formatMessage({
                id: messageIds.reconcile.upgrade.selectAnAccountForUpgrade,
              })}
            </div>
            <div
              css={css`
                font-size: 13px;
                line-height: 20px;
              `}
            >
              {intl.formatMessage({
                id: messageIds.reconcile.upgrade
                  .$selectAnAccountForUpgradeDescription,
              })}
              &nbsp;
              <AppHyperLink
                href={productDocumentations.upgradeForUnifiedPortal}
                externalLink={true}
              >
                {intl.formatMessage({
                  id: messageIds.reconcile.upgrade
                    .learnMoreAboutTenantLevelAccounts,
                })}
              </AppHyperLink>
            </div>
            {loadingAccounts ? (
              <Spinner size={SpinnerSize.large} />
            ) : (
              <>
                {selectedAccount != null && (
                  <MessageBar
                    messageBarType={MessageBarType.info}
                    styles={{ root: { flexShrink: 0 } }}
                  >
                    {intl.formatMessage(
                      {
                        id: messageIds.reconcile.upgrade
                          .$selectedAccountBannerMessage,
                      },
                      {
                        account:
                          selectedAccount.friendlyName ||
                          selectedAccount.accountName,
                        subscription: selectedAccount.subscription,
                      }
                    )}
                    &nbsp;
                    <AppHyperLink
                      href={productDocumentations.upgradeForUnifiedPortal}
                      externalLink={true}
                    >
                      {intl.formatMessage({
                        id: messageIds.shared.learnMore,
                      })}
                    </AppHyperLink>
                  </MessageBar>
                )}
                <FilterBox
                  styles={{ root: { width: 300 } }}
                  iconProps={{ iconName: "Filter" }}
                  placeholder={intl.formatMessage({
                    id: messageIds.reconcile.upgrade.filterByKeyword,
                  })}
                  value={localFilterText}
                  onChange={onFilterValueChange}
                />
                {pageCount > 1 && (
                  <Text>
                    {intl.formatMessage(
                      {
                        id: messageIds.reconcile.upgrade
                          .showingNumberOfAccounts,
                      },
                      {
                        start: currentPage * pageSize + 1,
                        end: currentPage * pageSize + items.length,
                        total: filteredAccounts.length,
                      }
                    )}
                  </Text>
                )}
                <div
                  css={css`
                    overflow-y: auto;
                    height: 370px;
                  `}
                  data-is-scrollable={true}
                >
                  <TableList
                    columns={columns}
                    items={items}
                    selectionMode={SelectionMode.single}
                    hideSelectAll={true}
                    onSelectedKeysChange={onSelectAccount}
                    disabledKeys={disabledKeys}
                    selectedKeys={selectedKeys}
                  />
                </div>
                <Pagination
                  currentPage={currentPage + 1}
                  pageCount={pageCount}
                  onPageChange={setCurrentPage}
                  strings={{
                    previous: intl.formatMessage({
                      id: messageIds.shared.previous,
                    }),
                    next: intl.formatMessage({ id: messageIds.shared.next }),
                    select: intl.formatMessage({
                      id: messageIds.shared.select,
                    }),
                    selectAriaLabel: intl.formatMessage({
                      id: messageIds.shared.selected,
                    }),
                    pageOfNumber: intl.formatMessage({
                      id: messageIds.shared.pageOfNumber,
                    }),
                  }}
                  styles={{
                    root: pageCount <= 1 && {
                      display: "none",
                    },
                  }}
                />
              </>
            )}
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              gap: 16px;
              margin-top: 24px;
            `}
          >
            <FabricPrimaryButton
              disabled={
                selectedAccount == null ||
                permittedMap[selectedAccount.accountName] !== true ||
                upgradingAccount
              }
              onClick={onUpgrade}
              text={intl.formatMessage({ id: messageIds.shared.upgrade })}
            />
            <FabricDefaultButton
              onClick={onCancel}
              text={intl.formatMessage({ id: messageIds.shared.cancel })}
            />
          </div>
        </div>
      </Modal>
      <BasePlanConfirmDialog
        isOpen={showBasePlanConfirmDialog}
        onConfirm={onConfirmBasePlanDialog}
        onCancel={onCancelBasePlanDialog}
      />
      <RegionConfirmDialog
        isOpen={showRegionConfirmDialog}
        account={selectedAccount}
        onConfirm={onConfirmRegionDialog}
        onCancel={onCancelRegionDialog}
      />
    </>
  );
});
