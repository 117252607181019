import { newGuid } from "@msbabylon/core";
import {
  createClientNameHeaderInterceptor,
  createErrorHandlingInterceptor,
} from "@msbabylon/purview-util/axios";
import { StandardPurviewAccountInfo as _StandardPurviewAccountInfo } from "@msbabylon/sdk-hub";
import {
  AuthService,
  createAuthorizationHeaderInterceptor,
  getResponseData,
} from "@msbabylon/shell-core";
import axios from "axios";
import { AccountMergeInfo } from "src/models/account-merge";
import { ShellApplication } from "src/models/app";
import {
  AccountListData,
  BabylonAccount,
  PermissionType,
} from "src/models/babylon";
import { LoggerService } from "src/services/LoggerService";
import {
  createTrackEvent,
  createTrackUnhandledErrorEvent,
} from "src/util/axios";
import { equalsCaseInsensitive } from "src/util/string";

export type StandardPurviewAccountInfo = _StandardPurviewAccountInfo & {
  sku?: {
    name: string;
  };
};

export interface TenantAccount {
  accountName: string;
  skuName: string;
}
export interface TenantAccountInfo {
  provisionedAccountType?: string;
  provisionedAccountProperties?: {
    accountName?: string;
  };
  isEligibleForFreeTier: boolean;
  isEligibleForManualReconcile?: boolean;
  eligibleLocations?: string[];
  accountProvisioningProperties?: {
    isEligibleForBasePlan?: boolean;
  };
}

const tenantHeaderName = `__TENANT_${newGuid()}`;

export class BabylonApi {
  private _generateAccountBaseUrl = (name?: string) =>
    this._application.appEnv.gatewayEndpoint.replace(
      "{0}",
      name ?? this._application.resourceName ?? ""
    );

  private _tenantAccountBaseUrl = (name?: string) =>
    this._application.appEnv.tenantGatewayEndpoint ??
    this._generateAccountBaseUrl(name);

  private readonly _axios = axios.create({});
  private _account: StandardPurviewAccountInfo | null = null;
  private _apiVersion = "2019-11-01-preview";
  private _apiVersion2206 = "2022-06-01-preview";
  private _apiVersion2210 = "2022-10-01-preview";
  private _apiVersion2310 = "2023-10-01-preview";

  constructor(
    private readonly _application: ShellApplication,
    private readonly _authService: AuthService,
    private readonly _logger: LoggerService
  ) {
    const requestInterceptors = [
      createAuthorizationHeaderInterceptor((config) => {
        if (config.headers == null) {
          config.headers = {};
        }
        // It's not a user input in the object key. https://github.com/nodesecurity/eslint-plugin-security/blob/master/docs/the-dangers-of-square-bracket-notation.md
        // eslint-disable-next-line security/detect-object-injection
        const tenantId = config.headers[tenantHeaderName] as string;
        // eslint-disable-next-line security/detect-object-injection
        delete config.headers[tenantHeaderName];
        return this._authService.acquireToken({
          scopes: [this._application.appEnv.gatewayAuthResourceUri],
          tenant: tenantId || undefined,
        });
      }),
      createClientNameHeaderInterceptor(),
    ];

    this._axios.interceptors.request.use(async (config) => {
      await Promise.all(
        requestInterceptors.map((interceptor) => interceptor(config))
      );

      return config;
    });

    this._axios.interceptors.response.use(
      (resp) => resp,
      createErrorHandlingInterceptor({
        trackUnhandledErrorEvent: createTrackUnhandledErrorEvent(this._logger),
        trackEvent: createTrackEvent(this._logger),
      })
    );
  }

  public async getCurrentInstance(): Promise<StandardPurviewAccountInfo> {
    if (this._account) {
      return this._account;
    }

    const account = await getResponseData(
      this._axios.get(`${this._generateAccountBaseUrl()}account`, {
        params: {
          "api-version": this._apiVersion,
        },
      })
    );
    this._account = account as StandardPurviewAccountInfo;
    return this._account;
  }

  public async getInstance(
    accountName: string,
    // FIXME: remove after latest API version ready
    useNewVersion?: boolean
  ): Promise<StandardPurviewAccountInfo> {
    const account = await getResponseData(
      this._axios.get(`${this._generateAccountBaseUrl(accountName)}account`, {
        params: {
          "api-version": useNewVersion
            ? this._apiVersion2310
            : this._apiVersion,
        },
      })
    );
    return account;
  }

  /**
   * Be careful that this is calling the gateway global endpoint which might fail in some cases in GA portal
   * Make sure you catch the error and handle it properly
   */
  public async checkTenant(
    account?: BabylonAccount
  ): Promise<TenantAccountInfo | undefined> {
    const tenantId = account?.$tenantId;
    const headers = tenantId
      ? { headers: { [tenantHeaderName]: tenantId } }
      : {};
    return await getResponseData(
      this._axios.get(
        `${this._tenantAccountBaseUrl()}provisioning/checkTenant`,
        {
          params: {
            "api-version": this._apiVersion2206,
          },
          ...headers,
        }
      )
    );
  }

  public async getInstanceSupportPlatformPE(
    accountName: string
  ): Promise<boolean | null | undefined> {
    try {
      const result = await getResponseData<{
        features: Record<string, boolean | null | undefined>;
      }>(
        this._axios.post(
          `${
            this._application.appEnv.gatewayEndpoint.replace(
              "{0}",
              accountName
            ) + "account"
          }/features`,
          { features: ["EnablePlatformPrivateEndpoint"] },
          {
            params: {
              "api-version": this._apiVersion2310,
            },
          }
        )
      );
      return result?.features?.EnablePlatformPrivateEndpoint;
    } catch (e) {
      return false;
    }
  }

  public async getSpecifiedInstancePermission(
    accountName: string,
    tenantId?: string
  ): Promise<{ permissions: string[] }> {
    type Obligation = { type: string; collections?: string[] };
    const response = await getResponseData(
      this._axios.post<Record<string, Obligation>>(
        `actions/collections/me`,
        [
          PermissionType.DataRead,
          PermissionType.CollectionRead,
          PermissionType.ShareRead,
        ],
        {
          baseURL:
            this._application.appEnv.gatewayEndpoint.replace(
              "{0}",
              accountName
            ) + "api/gateway",
          headers: {
            [tenantHeaderName]: tenantId!,
          },
        }
      )
    );
    const result: string[] = [];
    Object.entries(response).forEach(([permission, obligation]) => {
      if (
        !(obligation.type === "NotApplicable" && obligation.collections == null)
      ) {
        result.push(permission);
      }
    });
    return {
      permissions: result,
    };
  }

  public async getAccountList(tenantId: string) {
    const accounts = [];
    const params: {
      $skipToken?: string;
    } = {};

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const accountsData: AccountListData = await getResponseData(
        this._axios.get(
          `${this._generateAccountBaseUrl("gateway")}api/gateway/accounts`,
          {
            baseURL: this._generateAccountBaseUrl("gateway"),
            params,
            headers: {
              [tenantHeaderName]: tenantId,
            },
          }
        )
      );

      accounts.push(...accountsData.accounts);

      if (accountsData.skipToken) {
        params.$skipToken = accountsData.skipToken;
      } else {
        break;
      }
    }

    return accounts;
  }

  public async getFirstPageAccountList() {
    const accountsData: AccountListData = await getResponseData(
      this._axios.get(
        `${this._generateAccountBaseUrl("gateway")}api/gateway/accounts`,
        {
          baseURL: this._generateAccountBaseUrl("gateway"),
        }
      )
    );
    return accountsData.accounts;
  }

  public async reconcileAccount(accountName: string) {
    await getResponseData(
      this._axios.post(
        `${this._generateAccountBaseUrl(
          accountName
        )}account/reconcile?api-version=${this._apiVersion2210}`
      )
    );
  }

  public async checkWhatIfReconcile(accountName: string): Promise<boolean> {
    try {
      const result = await getResponseData(
        this._axios.post(
          `${this._generateAccountBaseUrl(
            accountName
          )}account/reconcile?api-version=${this._apiVersion2210}`,
          undefined,
          {
            headers: {
              "x-ms-operation-whatif": true,
            },
          }
        )
      );
      return equalsCaseInsensitive(result, "OperationWhatIfSuccess");
    } catch (e) {
      return false;
    }
  }

  public async listAccountMerges(
    accountName: string
  ): Promise<AccountMergeInfo[]> {
    const result = await getResponseData(
      this._axios.get(
        `${this._generateAccountBaseUrl(accountName)}datamap/api/merges`,
        {
          params: { "api-version": "2024-02-01-preview" },
        }
      )
    );
    return result as AccountMergeInfo[];
  }
}
