import { containsCaseInsensitive } from "@adux/common-react";
import { rootModels, toErrorMessage } from "@msbabylon/shell-framework";
import { delay } from "lodash-es";
import { createModel, createSelector } from "nyax";
import { EMPTY } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  switchMapTo,
  tap,
} from "rxjs/operators";
import { TenantAccountInfo } from "src/apis/BabylonApi";
import { getUnifiedPortalUrl } from "src/features";
import messageIds from "src/locales/messageIds";
import { defaultShellModelBuilder } from "src/store/defaultModelBuilder";
import {
  babylonAccountArmHelperModel,
  babylonAccountHelperModel,
} from "src/store/shell-models/babylon/account/helper";
import { babylonInstanceEntityModel } from "src/store/shell-models/babylon/instance";
import { redirectToLandingPage } from "src/util/redirect";
import { naturalSort } from "src/util/sort";
import { equalsCaseInsensitive } from "src/util/string";

export interface AccountItem {
  accountName: string;
  friendlyName?: string;
  subscription?: string;
  resourceId?: string;
  location?: string;
  publicNetworkAccess?: string;
}
export const uiReconcileSelectAccountModel = createModel(
  class extends defaultShellModelBuilder {
    public initialState() {
      return {
        ...super.initialState(),
        initialized: false,
        loadingAccounts: false,
        upgrading: false,
        selectedAccount: undefined as undefined | string,
        filterText: "",
        currentPage: 0,
        pageSize: 10,
        permittedMap: {} as Record<string, boolean | "INPROGRESS">,
        tenantInfo: undefined as TenantAccountInfo | undefined,
        platformPeEnabledAccounts: [] as string[],
      };
    }

    public reducers() {
      return {
        ...super.reducers(),
        setInitialized: () => {
          this.state.initialized = true;
        },
        setLoadingAccounts: (value: boolean) => {
          this.state.loadingAccounts = value;
        },
        setUpgrading: (value: boolean) => {
          this.state.upgrading = value;
        },
        setSelectedAccount: (value?: string) => {
          this.state.selectedAccount = value;
        },
        setFilterText: (value: string) => {
          this.state.filterText = value;
        },
        setCurrentPage: (value: number) => {
          this.state.currentPage = value;
        },
        setPermittedMap: (payload: {
          accountName: string;
          value: boolean | "INPROGRESS";
        }) => {
          // eslint-disable-next-line security/detect-object-injection
          this.state.permittedMap[payload.accountName] = payload.value;
        },
        setTenantInfo: (value?: TenantAccountInfo) => {
          this.state.tenantInfo = value;
        },
        setPlatformPeEnabledAccounts: (value: string[]) => {
          this.state.platformPeEnabledAccounts = value;
        },
      };
    }

    public selectors() {
      return {
        ...super.selectors(),
        accounts: () =>
          this.getContainer(babylonAccountHelperModel).getters.items,
        combinedAccounts: createSelector(
          () => this.getContainer(babylonAccountHelperModel).getters.items,
          () => this.getContainer(babylonAccountArmHelperModel).getters.items,
          () =>
            this.getContainer(rootModels.azure.subscription.entity).getters
              .bySubscriptionId,
          () => this.getContainer(babylonInstanceEntityModel).state.byId,
          () => this.state.platformPeEnabledAccounts,
          (
            babylonAccounts,
            armAccounts,
            subscriptionById,
            instanceById,
            platformPeEnabledAccounts
          ) => {
            return babylonAccounts.map((account) => {
              const matchedArmAccount = armAccounts.find((armAccount) =>
                equalsCaseInsensitive(armAccount.name, account.name)
              );
              const matchedBabylonInstance = instanceById[account.name];
              const resourceId =
                matchedArmAccount?.id ?? matchedBabylonInstance?.id;
              const subscriptionId =
                resourceId != null ? resourceId.split("/")[2] : undefined;
              return {
                accountName: account.name,
                friendlyName: account.friendlyName,
                subscription: subscriptionId
                  ? // eslint-disable-next-line security/detect-object-injection
                    subscriptionById[subscriptionId]?.displayName ??
                    subscriptionId
                  : undefined,
                location:
                  account.location ??
                  matchedArmAccount?.location ??
                  matchedBabylonInstance?.location,
                resourceId,
                publicNetworkAccess: platformPeEnabledAccounts.includes(
                  account.name
                )
                  ? "Enabled"
                  : matchedArmAccount?.properties?.publicNetworkAccess ??
                    matchedBabylonInstance?.properties?.publicNetworkAccess,
              };
            });
          }
        ),
        selectedAccount: createSelector(
          () => this.state.selectedAccount,
          (): AccountItem[] => this.getters.combinedAccounts,
          (accountName, accounts) =>
            accounts.find((account) => account.accountName === accountName)
        ),
        filteredAccounts: createSelector(
          () => this.state.filterText,
          (): AccountItem[] => this.getters.combinedAccounts,
          (filterText, accounts) => {
            return accounts
              .filter((account) => {
                return containsCaseInsensitive(
                  `${account.accountName}\n${account.friendlyName}\n${account.subscription}\n${account.location}`,
                  filterText
                );
              })
              .sort((a, b) => naturalSort(a.accountName, b.accountName));
          }
        ),
        currentPageAccounts: createSelector(
          (): AccountItem[] => this.getters.filteredAccounts,
          () => this.state.currentPage,
          () => this.state.pageSize,
          (filteredAccounts, currentPage, pageSize) => {
            return filteredAccounts.slice(
              currentPage * pageSize,
              (currentPage + 1) * pageSize
            );
          }
        ),
        currentPageAccountsTableItems: createSelector(
          (): AccountItem[] => this.getters.currentPageAccounts,
          () => this.state.permittedMap,
          (currentPageAccounts, permittedMap) => {
            return currentPageAccounts.map((e) => {
              return {
                ...e,
                key: e.accountName,
                displayText:
                  e.friendlyName && e.friendlyName !== e.accountName
                    ? `${e.friendlyName} (${e.accountName})`
                    : e.accountName,
                permitted: permittedMap[e.accountName],
                publicNetworkAccess: e.publicNetworkAccess,
              };
            });
          }
        ),
        pageCount: createSelector(
          (): AccountItem[] => this.getters.filteredAccounts,
          () => this.state.pageSize,
          (filteredAccounts, pageSize) => {
            return Math.ceil(filteredAccounts.length / pageSize);
          }
        ),
        selectedAccountRegionMatched: createSelector(
          (): AccountItem | undefined => this.getters.selectedAccount,
          () => this.state.tenantInfo,
          (selectedAccount, tenantInfo) => {
            return (
              selectedAccount?.location &&
              tenantInfo?.eligibleLocations &&
              tenantInfo.eligibleLocations.some((location) =>
                equalsCaseInsensitive(location, selectedAccount.location)
              )
            );
          }
        ),
        basePlanIsUsed: createSelector(
          () => this.state.tenantInfo,
          (tenantInfo) => {
            return tenantInfo?.accountProvisioningProperties
              ?.isEligibleForBasePlan;
          }
        ),
      };
    }

    public effects() {
      return {
        ...super.effects(),
        onInitialize: async () => {
          try {
            await this.actions.checkIsEligible.dispatch({});
            const tenantInfo = this.state.tenantInfo;
            if (tenantInfo?.provisionedAccountProperties?.accountName) {
              const url = await getUnifiedPortalUrl(this.dependencies);
              window.location.href = url.toString();
            }
            if (tenantInfo?.isEligibleForManualReconcile) {
              this.actions.fetchAccounts.dispatch({});
            } else {
              this.dependencies.toast.create({
                type: "warning",
                title: this.dependencies.intl.formatMessage({
                  id: messageIds.reconcile.upgrade.notEligible,
                }),
                description: this.dependencies.intl.formatMessage({
                  id: messageIds.reconcile.upgrade.notEligibleAndRedirect,
                }),
                time: new Date().toISOString(),
              });
              delay(() => {
                redirectToLandingPage("", this.dependencies);
              }, 5000);
            }
          } catch (error) {
            redirectToLandingPage("", this.dependencies);
          } finally {
            this.actions.setInitialized.dispatch({});
          }
        },
        checkIsEligible: async () => {
          const tenantInfo = await this.dependencies.babylonApi.checkTenant();
          this.actions.setTenantInfo.dispatch(tenantInfo);
        },
        fetchAccounts: async () => {
          try {
            this.actions.setLoadingAccounts.dispatch(true);
            const userTenantId = this.getContainer(rootModels.auth).getters
              .tenantId;
            if (userTenantId) {
              await this.getContainer(
                babylonAccountHelperModel
              ).actions.readFromGateway.dispatch({ tenantId: userTenantId });

              // read from arm to get location / subscription
              this.getContainer(
                babylonAccountArmHelperModel
              ).actions.readFromResourceGraph.dispatch({
                tenantId: userTenantId,
              });
            }
          } catch (e) {
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          } finally {
            this.actions.setLoadingAccounts.dispatch(false);
          }
        },
        CheckIfAccountsPermitted: async (accounts: AccountItem[]) => {
          await Promise.all(
            accounts
              .filter(
                (account) =>
                  this.state.permittedMap[account.accountName] === undefined
              )
              .map((account) =>
                (async () => {
                  try {
                    this.actions.setPermittedMap.dispatch({
                      accountName: account.accountName,
                      value: "INPROGRESS",
                    });
                    const permitted =
                      await this.dependencies.babylonApi.checkWhatIfReconcile(
                        account.accountName
                      );
                    this.actions.setPermittedMap.dispatch({
                      accountName: account.accountName,
                      value: permitted,
                    });
                    // load account info if the account is permitted but doesn't have location info
                    if (permitted) {
                      this.actions.fetchAccountLocation.dispatch(
                        account.accountName
                      );
                    }
                  } catch (error) {
                    this.actions.setPermittedMap.dispatch({
                      accountName: account.accountName,
                      value: false,
                    });
                  }
                })()
              )
          );
        },
        fetchAccountLocation: async (accountName: string) => {
          try {
            const armAccounts = this.getContainer(babylonAccountArmHelperModel)
              .getters.items;
            const accountFromArm = armAccounts.find((armAccount) =>
              equalsCaseInsensitive(armAccount.name, accountName)
            );
            let publicNetwork: string | undefined;
            if (accountFromArm) {
              publicNetwork = accountFromArm?.properties?.publicNetworkAccess;
            } else if (
              // eslint-disable-next-line security/detect-object-injection
              this.getContainer(babylonInstanceEntityModel).state.byId[
                accountName
              ] == null
            ) {
              const accountInfo =
                await this.dependencies.babylonApi.getInstance(accountName);
              await this.getContainer(
                babylonInstanceEntityModel
              ).actions.setItems.dispatch([accountInfo]);
              publicNetwork = accountInfo?.properties?.publicNetworkAccess;
            }
            if (publicNetwork === "Disabled") {
              this.actions.fetchAccountSupportPlatformPe.dispatch(accountName);
            }
          } catch {
            // noop
          }
        },
        fetchAccountSupportPlatformPe: async (accountName: string) => {
          try {
            const result =
              await this.dependencies.babylonApi.getInstanceSupportPlatformPE(
                accountName
              );
            if (result) {
              this.actions.setPlatformPeEnabledAccounts.dispatch([
                ...this.state.platformPeEnabledAccounts,
                accountName,
              ]);
            }
          } catch {
            // noop
          }
        },
        upgradeAsync: async () => {
          try {
            this.actions.setUpgrading.dispatch(true);
            const account = this.state.selectedAccount;
            // eslint-disable-next-line security/detect-object-injection
            if (account && this.state.permittedMap[account] === true) {
              this.dependencies.logger.logEvent(
                "info",
                "Reconcile",
                "start to reconcile account."
              );
              await this.dependencies.babylonApi.reconcileAccount(account);
              const url = await getUnifiedPortalUrl(this.dependencies);
              window.location.href = url.toString();
            }
          } catch (e) {
            this.dependencies.logger.logEvent(
              "error",
              "Reconcile",
              "failed to reconcile account."
            );
            this.dependencies.toast.create({
              type: "error",
              title: this.dependencies.intl.formatMessage({
                id: messageIds.shared.error,
              }),
              description: toErrorMessage(e),
              time: new Date().toISOString(),
            });
          } finally {
            this.actions.setUpgrading.dispatch(false);
          }
        },
      };
    }

    public epics() {
      return {
        ...super.epics(),
        checkPermissionOnPageChange: () => {
          return this.rootAction$.pipe(
            filter(() => this.state.initialized),
            map(() =>
              JSON.stringify(
                this.getters.currentPageAccounts.map((e) => e.accountName)
              )
            ),
            distinctUntilChanged(),
            tap(() => {
              const currentPageAccounts = this.getters.currentPageAccounts;
              this.actions.CheckIfAccountsPermitted.dispatch(
                currentPageAccounts
              );
            }),
            switchMapTo(EMPTY)
          );
        },
      };
    }
  }
);
